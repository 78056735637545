import React from 'react'
import Container from 'react-bootstrap/Container'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Document } from 'react-pdf';
import { Page } from 'react-pdf';
import {pdfjs} from 'react-pdf'
import {API_URLBASE } from './App'
import './App.css'
import queryString from 'query-string'
import Spinner from 'react-bootstrap/Spinner'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFPage extends React.Component {


	constructor(props) {
		super(props)
		const values = queryString.parse(this.props.location.search, {arrayFormat: 'index'});
		let book_id = values.book_id
		let start_page = parseInt(values.start_page)
		let end_page = parseInt(values.end_page)
		let pdf_url = API_URLBASE + "/books/" + book_id + "/pdf_for_page_range?" + queryString.stringify({start_page: start_page, end_page: end_page})
		console.log(pdf_url)
		this.state = {
			pdf_url: pdf_url,
			book_id: values.book_id,
			page_id: values.page_id,
			terms: values.terms,
			loading: true
		}
		this.middlePage = React.createRef()
		this.customTextRenderer = this.customTextRenderer.bind(this);
		// this.resultClicked = this.resultClicked.bind(this);
	}

	

	customTextRenderer(textItem) {
		let pdfText = textItem.str.replace(/\s+/g, ' ')

		var buffer = [pdfText]
		var newBuffer = []
		let terms = this.state.terms
		terms.forEach((term) => {
			buffer.forEach((strOrObj) => {
				if(typeof strOrObj === "string" && strOrObj.includes(term)) {
					let arr = strOrObj.split(term)
					.reduce((strArray, currentValue, currentIndex) => (

						currentIndex === 0
						? [...strArray, currentValue]
						: [strArray, (
							<mark className="pdf-mark">{term}</mark>),
						currentValue
						]
						));
					//console.log(arr)
					newBuffer = [...newBuffer, ...arr]
				} else {
					newBuffer.push(strOrObj)
				}
			});
			buffer = newBuffer.slice()
			newBuffer = []
		});

		return buffer;

	}

	render() {
		if(this.state.pdf_url === "") {
			return(
				<Container style={{display: "flex", "justifyContent": "center"}}>
				
				</Container>

				);
		} else {
			return(
				<Container style={{justifyContent: "center"}}>
				<Row>
				<Col lg={12}>
				<Document 
				style={{display: "flex", "justifyContent": "center"}} 
				file={this.state.pdf_url}
				loading={
					<Spinner animation="border" style={{marginLeft: "50%", marginTop: "200px"}} sz="lg"/>
				} 
				>
				<Page  key={1} 
					loading=""
					renderMode="canvas" scale={1.5} pageNumber={1} customTextRenderer={(textItem) => this.customTextRenderer(textItem)} />
				<div ref={this.middlePage} >
				<Page  key={2} 
					loading={<Spinner animation="border" style={{marginLeft: "50%", marginTop: "200px"}} sz="lg"/>}
					onRenderSuccess={() => {console.log("loaded"); window.scrollTo(0, this.middlePage.current.offsetTop)}}
					renderMode="canvas" scale={1.5} pageNumber={2} customTextRenderer={(textItem) => this.customTextRenderer(textItem)} />
				</div>
				<Page  key={3} 
					loading=""
					renderMode="canvas" scale={1.5} pageNumber={3} customTextRenderer={(textItem) => this.customTextRenderer(textItem)} />
				</Document>
				</Col>
				</Row>

				</Container>
				);
		}
	}
}

//So I think the idea is that after finishing the PDF load, we need to load the before and after pages
//we'll need to maintain the scrolling, so probably have to put them in a container

export default PDFPage;