import React from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'


class SearchBar extends React.Component {

	constructor(props) {
		super(props)

		let options = props.suggestions
		this.updateSuggestions = this.updateSuggestions.bind(this)
		this.state = {
			options: options
		};
	}

	updateSuggestions() {
		let options = []
		this.props.suggestions.forEach((option, index) => {
			options.push(option)
			if(option.sub_entries.length > 0) {
				option.sub_entries.forEach((suboption) => {
					options.push(suboption)
				})
			}
		})
		return options
	}

	render() {
		const handleSelectBooks = (evt) => {
			this.props.onSelectBooks()
		};



		return (
			<>
			<Row style={{marginTop: "2%", marginBottom: "2%"}}>
			<Col lg={12} sm={12} md={12}>
			<div className="d-flex flex-column">
			<ButtonGroup aria-label="Action Buttons" variant="light" sz="md" >
				<Button variant="light" onClick={handleSelectBooks}>Select Books</Button>
				<Button variant="light" onClick={(evt) => {this.props.onHelp(evt)}}  type="button">Help</Button>
				<Button variant="light"   type="button" href="https://buzzellbooks.fifthpress.org/wordpress/">Forum</Button>
				<Button variant="light"   type="button" href="http://fifthpress.org/book-sales/">Buy</Button>
			</ButtonGroup>
			</div>
			</Col>
			</Row>

			<Row style={{marginTop: "2%"}}> 

			

			<Col lg={12} sm={12} md={12}>
			<InputGroup>
			<Typeahead 
				id='101'
				labelKey="term"

				options={this.updateSuggestions()}
				onKeyDown={(evt) => {
					if(evt.key === 'Enter') {
						this.props.onSearch(evt.target.value)
					}
				}}
				placeholder="Type in a Search Term and Press Enter"
				
				renderMenuItemChildren={(option, props, index) => {
						if("sub_entries" in option) {
							//a main entry
							if(option.neologism) {
								return ("~"+option.term+"~");
							}
							return (option.term);							
						} else if("entry_id" in option) {
							//a sub entry
							if(option.neologism) {
								return (<React.Fragment>&nbsp;&nbsp;&nbsp;~{option.term}~</React.Fragment>)
							}
							return (<React.Fragment>&nbsp;&nbsp;&nbsp;{option.term}</React.Fragment>)
						}

					}	
				}

				emptyLabel=""

				minLength={0}
				
				onChange={(selectedElement) => {
						this.props.onChange(selectedElement)
						if(selectedElement.length >0) {
							this.props.onSearch(selectedElement[0].term)
						}
					}
				}
				
				selected={this.props.selected}
				
				selectHintOnEnter={false}
				ref={(typeahead) => this.typeahead = typeahead}

			/>
			<InputGroup.Append>
			<Button  variant="light" 
					onClick={() => {
						let query = this.typeahead.getInstance().getInput();
						this.props.onSearch(query.value)
					}}> Search</Button>
					
			</InputGroup.Append>
			</InputGroup>
			</Col>
			</Row>

			
			</>
		);
	}
}

// renderMenu={(results, menuProps) => (
// 					<Menu {...menuProps}>
// 						{results.map((result, index) => (
// 							<MenuItem option={result} position={index}>
// 							{index == 0 && this.props.selected.length == 0 ? 
// 								{<a class="dropdown-item active" href="#">{result.term}</a> 
// 																: result.term}}
// 							</MenuItem>


// 						))}
// 					</Menu>
// 					)
// 				}

export default SearchBar;