import React from 'react';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ListGroup from 'react-bootstrap/ListGroup'
//import ListGroupItem from 'react-boostrap/ListGroupItem'
/*
Expected Props:
author
title
pages (array)
image (eventually)
*/

class Book extends React.Component {
	constructor(props) {
		super(props)
		this.text.bind(this)

	}

	text() {
		console.log("in text!")
	}

	//return -1 if closing tag not found
	// return index of closing tag if found
	// 
	findClosingTagIndex(array,startingIndex, tag) {
		for(var j = startingIndex; j < array.length; j++) {
			console.log(array[j])
			if(array[j] === tag) {
				return j
			}
		}
		return -1;
	}

	

	convertMarkTags(html,page) {
	
	
		var buffer = []
		
		var array = html.split(/<mark>/g)

		if(array.length === 1) {
			buffer.push(array[0])
		} else {
			array.forEach((substr,currentIndex) => {
				if(substr.includes("</mark>")) {
					//the beginning of this string was a mark, so push to array
					let [markedText, after] = substr.split("</mark>")
					buffer.push( 
						<mark key={this.props.id + ":" + page.id + ":" + currentIndex}> 
							<a href="#" style={{color: "black", textDecoration: ""}}  
								onClick={(evt) => { evt.preventDefault(); this.props.resultClicked(this.props.id, page) }}>
								{markedText}
							</a>
						</mark>
						);
					buffer.push(after)
				} else {
					//this was text before a mark
					buffer.push(substr);
				}
			});

		}
		let footnoteBuffer = []
		let foundText = false
		//now search for <br/><sup>18</sup> (footnotes)
		buffer.forEach((strOrElem, bufferIndex) => {
			if(typeof strOrElem ===  'string') {
				//look for footnote
				strOrElem.split(/<br\/><sup>/).forEach((substr,currentIndex) => {
					if(substr.includes("</sup>")) {
						let [superScriptedText, after] = substr.split("</sup>")
						//don't insert line break if the footnote is the first text in the hit
						
						
						if(foundText) {
							
							footnoteBuffer.push(<br key={superScriptedText} />)
						}
						footnoteBuffer.push(
								<sup key={superScriptedText*10}>{superScriptedText}</sup>
							);
						footnoteBuffer.push(after)
						foundText=true
					} else {
						footnoteBuffer.push(substr)
						if(substr.match(/\w/)) {foundText=true}
					}
				}); 
			} else {
				//an element, just push it
				footnoteBuffer.push(strOrElem)
				foundText=true
			}
		});

		return footnoteBuffer

	}


	render() {
		let totalHits = 0
		let innerHits = []
		const offset = this.props.page_offset //ex. -15
		
		this.props.pages.map((page) => {
			// console.log(page)
			const pageNumber = page.page_number
			const pageHits = page.context
			totalHits += pageHits.length

			const offsetPage = (pageNumber + offset > 0) ? <>p.&nbsp; {(pageNumber + offset )}</> : "Intro"

			innerHits.push(pageHits.map ((hit,index) => (
				

				<ListGroup.Item key={pageNumber.toString() + "_"  + index.toString()}>
					
				<Container>
				<Row>
				<Col lg={1} md={2}  style={{textAlign: "center"}}>{offsetPage} </Col>
				<Col md={10} lg={11}
					style={{textAlign: "left"}}>{
				this.convertMarkTags(hit, page)
				}</Col>
				</Row>
				</Container>
				</ListGroup.Item>
				)
			));
			return null
		});
		return(
			<Card>
				<Card.Header>{this.props.title} ({this.props.year}) -- {totalHits} hits</Card.Header>
				
				<ListGroup>
				{innerHits}
				</ListGroup>
			</Card>
		);
	}

}

export default Book;
